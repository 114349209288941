@import '~antd/dist/antd.css';

$dark: #2B2E45;
$dark-light-3: #393D57;
$dark-light-5: #3A3F5D;
$warning: #D60664;
$warning-light-3: #DE8F30;
$purple: #885CFF;
$purple-light-3: #747dfc;
$purple-light-5: #d5c7fc;
$white: #ffffff;
$light: #FDD775;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(to bottom, $dark-light-3, $dark);
}

.max-width-500 {
  max-width: 500px;
  margin: 0 auto;
}

@media screen and (max-width: 375px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 320px) {
  html {
    font-size: 12px;
  }
}

.text-center {
  text-align: center;
  font-size:150%;
}



.border-b {
  border-bottom: 1px solid #e9e9e9;
}

.slim-no-scroll {
	&::-webkit-scrollbar {
		width: 0;
    height: 0;
	}
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.round-md {
  border-radius: 1rem;
}

.d-flex {
  display: flex;
}

.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-item {
  display: flex !important;
  .flex-label {
    flex: 50 0 auto;
  }
  .flex-content {
    flex: 1;
    overflow: auto;
    width: 5%;
    text-align:center;
  }
}

.flex-vertical {
  display: flex;
  flex-direction: column;
  .flex-header,
  .flex-footer {
    flex: 0 0 auto;
  }
  .flex-content {
    flex: 1;
    overflow: auto;
    
  }
}

.position-relative {
  position: relative;
}

.round-md {
  border-radius: 1rem;
}

.bg-purple {
  background: $purple;
}

.bg-dark-5 {
  background: $dark-light-5;
}

.color-white {
  color: $white;
}

.color-light {
  color: $light;
}

.color-purple-5 {
  color: $purple-light-5;
}

.color-warning-3 {
  color: $warning-light-3;
}

.color-purple {
  color: $purple;
}

.text-bold {
  font-weight: bold;
}

.text-title {
  font-size: 1.5rem;
}

.text-plus {
  font-size: 1.25rem;
}

.text-md {
  font-size: 1rem;
}

.text-sm {
  font-size: 0.75rem;
}

.text-bold {
  font-weight: bold;
}

.px-6 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.p-5 {
  padding: 2rem;
}

.py-5 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-5 {
  padding-bottom: 2rem;
}

.p-4 {
  padding: 1.25rem;
}

.px-4 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-4 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pb-4 {
  padding-bottom: 1.25rem;
}

.p-3 {
  padding: 1rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pt-3 {
  padding-top: 1rem;
}

.py-2 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.pb-2 {
  padding-bottom: 0.6rem;
}

.pl-2 {
  padding-left: 0.6rem;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.px-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-5 {
  padding: 0 3rem;
}

.ml-1 {
  margin-left: 0.3rem;
}

.mr-1 {
  margin-right: 0.3rem;
}

.mt-1 {
  margin-top: 0.3rem;
}

.ml-2 {
  margin-left: 0.6rem;
}

.mr-2 {
  margin-right: 0.6rem;
}

.mt-2 {
  margin-top: 0.6rem;
}

.mt-2-5 {
  margin-top: 0.8rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mr-3 {
  margin-right: 1rem;
}

.ml-3 {
  margin-left: 1rem;
}

.mt-4 {
  margin-top: 1.25rem;
}

.mb-4 {
  margin-bottom: 1.25rem;
}

.mt-4-5 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 2rem;
}

.mt-6 {
  margin-top: 3rem;
}

.ant-btn {
  height: 2.8rem;
  border-radius: 0.5rem;
  font-size: 1rem;

  &::before {
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }

  &::after {
    box-shadow: 0 0 1rem rgba(253, 215, 117, 0.3) !important;
  }

  &[disabled] {
    &::before {
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      z-index: 1;
      background: #fff;
      border-radius: inherit;
      display: inline-block;
      opacity: 0.35;
      transition: opacity 0.2s;
      content: '';
      pointer-events: none;
    }
    &:hover, &:active, &:focus {
      filter: brightness(1);
    }
  }

  &.ant-btn-warning {
    border: none !important;
    background: linear-gradient(to right, $warning, $warning-light-3);
    color: $white !important;
    box-shadow: 0 0 1rem rgba(253, 215, 117, 0.3);
    
    &:not(.ant-btn-loading) {
      &:hover {
        filter: brightness(1.05);
      }
      &:active {
        filter: brightness(0.95);
      }
    }

    &[disabled] {
      &:hover, &:active, &:focus {
        background: linear-gradient(to right, $warning, $warning-light-3);
        box-shadow: 0 0 1rem rgba(253, 215, 117, 0.3);
      }
    }
  }

  &.ant-btn-purple {
    border: none !important;
    background: linear-gradient(to right, $purple, $purple-light-3);
    color: $white !important;
    box-shadow: 0 0 1rem rgba(183, 156, 255, 0.3);

    &:not(.ant-btn-loading) {
      &:hover {
        filter: brightness(1.05);
      }
      &:active {
        filter: brightness(0.95);
      }
    }

    &[disabled] {
      &:hover, &:active, &:focus {
        background: linear-gradient(to right, $purple, $purple-light-3);
        box-shadow: 0 0 1rem rgba(253, 215, 117, 0.3);
      }
    }
  }
}

.ant-progress {
  .ant-progress-outer {
    .ant-progress-inner {
      height: 0.4rem;
      border-radius: 0.4rem;
      background: rgba(255, 255, 255, 0.1);
      .ant-progress-bg {
        height: 0.4rem !important;
        border-radius: 0.4rem !important;
      }
    }
  }
  .ant-progress-text {
    color: $white;
    font-size: 0.875rem;
  }

  &.warning {
    .ant-progress-outer {
      .ant-progress-inner {
        .ant-progress-bg {
          background: linear-gradient(to right, $warning, $warning-light-3);
        }
      }
    }
  }
}

.ant-input {
  height: 2.8rem;
  border-radius: 0.5rem;
  background: $dark-light-5;
  border-color: $dark-light-5;
  color: $white;
  font-size: 0.875rem;
  &:focus, &:hover {
    border-color: $warning-light-3;
  }
}

.level-text {
  position: absolute;
  top: 55%;
  left: 45%;
  transform: translate(-50%, -50%);
  text-shadow: 0.1rem 0.1rem 0 #B31399;
}

.input-box {
  .ant-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}

img {
  &.right {
    transform: rotateY(180deg);
  }
}

@keyframes scroll {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -100%);
  }
}

.win-item {
  height: 2rem;
  line-height: 2rem;
}

.third-wrapper {
  height: 10rem;
  .third-box {
    animation: scroll 0s linear 500ms infinite;
  }
}